<template lang="">
  <b-card>
    <basetable
      ref="basetable"
      :dataurl="dataurl+'/'+user_id+'/workshop'"
      :fields="fields"
      :baseroute="baseroute"
      :is-edit="false"
      :is-add="false"
      :is-filter="this.$route.meta.filterIndex?true:false"
      :title="this.$route.meta.titleIndex?this.$route.meta.titleIndex:''"
    >
      <template v-slot:buttonaddon>
        <b-button
          v-b-modal="'modal-select-workshop'"
          class="mr-1"
          size="sm"
          variant="success"
        >
          Tambah Bengkel Vendor
        </b-button>
      </template>
      <template #cell(_)="data">
        <div>
          <b-button
            size="sm"
            variant="primary"
            class="mr-1 btn-icon"
            @click="viewForm(data)"
          >
            <feather-icon icon="FolderIcon" />
          </b-button>
          <b-button
            v-if="$route.meta.isDelete == null"
            size="sm"
            variant="danger"
            class="mr-1 btn-icon"
            @click="confirmDelete(data)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
      </template>
    </basetable>
    <b-modal
      :id="'modal-select-workshop'"
      size="lg"
      centered
      title="Pilih Bengkel"
      hide-footer
    >
      <div><basetableselect
        :dataurl="dataurlworkshop"
        :fields="fields"
        @onRowSelected="onRowSelected"
      /></div>
    </b-modal>
  </b-card>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import basetable from '@/views/base/BaseTable.vue'
import basetableselect from '@/views/base/BaseSelectTable.vue'
import baseSelect from '@/views/base/BaseSelect.vue'
import { BCard,BButton,BFormGroup,BFormSelect,BRow,BCol, BModal } from 'bootstrap-vue'

export default {
  components: {
    basetableselect,
    basetable,
    BCard,
    BButton,
    BFormGroup,
    baseSelect,
    BFormSelect,
    BRow,
    BCol,
    BModal
  },
  data() {
    return {
      userType:null,
      user:null,
      dataurl:"/vendor",
      baseroute:"workshop",
      fields: [
        { key: 'name', label: 'Nama Bengkel', sortable: true },
        { key: 'address', label: 'Alamat', sortable: true },
        { key: 'city', label: 'Kota', sortable: true },
        { key: 'phone', label: 'Telephone', sortable: true }
      ],
      dataurlworkshop:"/workshop",
      user_id:null
    }
  },
  created(){
    if(this.$store.state.profile.info){
      var info = this.$store.state.profile.info
      this.userType = info.contact_type.split('\\').pop().toLowerCase()
      this.user = this.$store.state.profile.info.contact
    }
    if(this.$route.meta.baseroute)
      this.baseroute = this.$route.meta.baseroute
    
    if(this.userType == 'vendor'){
      this.user_id = this.user.id
    }else{
      this.user_id = this.$route.params.id
    }
  },
  methods:{
    onRowSelected(val) {
      this.$bvModal.hide('modal-select-workshop')
      this.$http.post(this.dataurl+'/'+this.user_id+'/workshop', {id:val.id})
      .then(()=>{
        this.successSubmit()
      })
      .catch(err=>{
        this.errorSubmit(err)
      })
    },
    successSubmit(){
      this.$refs.basetable.fetchData()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
    viewForm(data){
      this.$router.push({ name: this.baseroute+'-view',params : { id: data.item.id} })
    },
    confirmDelete(data){
      const {id} = data.item
      this.$bvModal
        .msgBoxConfirm('Are you sure delete '+Object.values(data.item)[1]+' ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete(this.dataurl+'/'+this.user_id+'/workshop?id='+id)
              .then(() => {
                this.$refs.basetable.fetchData()
            })
          }
        })
    }
  }
}
</script>
<style lang="">

</style>
